<template>
  <div style="margin: 0 0 50px 0">
    <userChat :key="$i18n.locale" :user="$route.params.user" :form="data.form" :entryID="data.md5ID" />
  </div>
</template>
<script>
export default {
  components: {
    userChat () { return import('@/components/chat/userChat.vue') }
  },
  props: {
    data: Object
  },
  data () {
    return {

    }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
